import React, { useState, useEffect } from "react"
import { Link, navigate } from 'gatsby'
import { Modal, Loading, Button, Spacer } from '@geist-ui/react'
import { ThumbsUp, ThumbsDown } from '@geist-ui/react-icons'


export default function FighterCard (props) {

  const [loading, setLoading] = useState(true)
  const [fighterone, setFighterOne] = useState({})
  const [fightertwo, setFighterTwo] = useState({})
  const [votes, setVotes] = useState([])
  const [update, setUpdate] = useState()
  const [votesloading, setVotesLoading] = useState(true)

  useEffect(() => {
    if (props.fighterone) {
      let fighterread = "fighters" + "-" + props.fighterone;
      fetch(`/.netlify/functions/fauna-read/${fighterread}`).then(response => {
          return response.json().then(response => (setFighterOne(response.data), setLoading(false)))
          .catch(error => setLoading(false))
      });
    }
    if (props.fightertwo) {
      let fighterread = "fighters" + "-" + props.fightertwo;
      fetch(`/.netlify/functions/fauna-read/${fighterread}`).then(response => {
          return response.json().then(response => (setFighterTwo(response.data), setLoading(false)))
          .catch(error => setLoading(false))
      });
    }
  }, [props.fighterone, props.fightertwo]);

  useEffect(() => {
      fetch(`/.netlify/functions/fauna-find/`, {
          body: JSON.stringify({
            index: 'votes_by_fight',
            id: props.fight
          }),
        method: 'POST'}).then((response) => {
          return response.json()}).then((response) => (setVotes(response.data || []), setVotesLoading(false)))
       .catch(error => setVotesLoading(false));
  }, [props.fight, update])


  useEffect(() => {
    if(props.ip && props.signingin) {
      if(props.signingin && props.signingin.split("-")[1] === props.fight && props.signingin.split("-")[0] === 'upvote') {
        vote(1);
        props.onChange(false);
      }
      else if(props.signingin && props.signingin.split("-")[1] === props.fight && props.signingin.split("-")[0] === 'downvote') {
        vote(-1);
        props.onChange(false);
      }
    }

  }, [props.ip, props.signingin])


  function deletevote(vote){
  setVotesLoading(true);
  fetch(`/.netlify/functions/fauna-rem/`, {
        body: JSON.stringify({
          collection: 'votes',
          id: vote,
        }),
      method: 'POST'}).then((response) => {
        return response.json()}).then((response) => (setUpdate(response), setVotesLoading(false), props.onChange(response)))
     .catch(error => setVotesLoading(false));
}

function vote (num) {
  if(props.ip) {
    if(props.myvote.length) {
      if(num > 0 && props.myvote[0][1] === 'down') {
        alert('You have already downvoted this fight');
      }
      else if(num > 0 && props.myvote[0][1] === 'up') {
        deletevote(props.myvote[0][0]['@ref'].id);
      }
      else if(num < 0 && props.myvote[0][1] === 'up') {
        alert('You have already upvoted this fight');
      }
      else if(num < 0 && props.myvote[0][1] === 'down') {
        deletevote(props.myvote[0][0]['@ref'].id);
      }
    }
    else {
      setVotesLoading(true);
      fetch(`/.netlify/functions/fauna-create/votes`, {
          body: JSON.stringify({
            fight: props.fight,
            ip: props.ip,
            direction: num > 0 ? 'up' : 'down'
          }),
          method: 'POST'
        }).then(response => {
          return response.json()}).then(response => (setVotesLoading(false), setUpdate(response), props.onChange(response)))
          .catch(error => (setVotesLoading(false), console.log(error)));
     }
    }
    else {
      props.onChange(num > 0 ? ('upvote-' + props.fight) : ('downvote-' + props.fight));
    }
  }


  return (<>
    <div className="fight">
         {!loading && fighterone.name && <div className="fighter" onClick={() => navigate('/fight/' + props.slug + '/')}>
           <img src={fighterone.photo} alt={fighterone.name} width="400px" />
           <h5>{fighterone.name}</h5>
         </div>}
         {loading && <Loading />}
         <div className="versus">
           <div className="sport-vs" onClick={() => navigate('/fight/' + props.slug + '/')}><span className="sports-font">{props.sport[1]}</span> {props.sport[0]}</div>
           <div className="vs" onClick={() => navigate('/fight/' + props.slug + '/')}>VS</div>
           <div className="badges">
             <div className={props.myvote.length && props.myvote[0][1] === 'up' ? "bordered badge" : "badge"}>
               <ThumbsUp size={35} color="#fff" onClick={() => vote(1)} />
               {votesloading ? <Loading /> : votes.filter((vote) => vote === 'up').length}
             </div>
             <div className={props.myvote.length && props.myvote[0][1] === 'down' ? "bordered badge" : "badge"}>
               <ThumbsDown size={35} color="#fff" onClick={() => vote(-1)} />
               {votesloading ? <Loading /> : votes.filter((vote) => vote === 'down').length}
             </div>
           </div>
           <Button scale={.5} type="secondary" onClick={() => navigate("/fight/" + props.slug + "/")} shadow>View Match Up</Button>
         </div>
         {loading && <Loading />}
         {!loading && fightertwo.name && <div className="fighter" onClick={() => navigate('/fight/' + props.slug + '/')}>
           <img src={fightertwo.photo} alt="{fightertwo.name}" width="400px" />
           <h5>{fightertwo.name}</h5>
         </div>}
         <div className="sport-banner" onClick={() => navigate('/fight/' + props.slug + '/')}><span className="sports-font">{props.sport[1]}</span> {props.sport[0]}</div>
       </div>
  </>)
}