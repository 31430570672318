import React, { useState, useEffect, useContext } from "react"
import Helmet from 'react-helmet'
import FightCard from '../components/FightCard'
import AddFight from '../components/AddFight'
import UserContext from '../components/context/UserContext'
import {
  SignUp,
  SignIn,
  SignedOut,
  useAuth
  } from 'gatsby-plugin-clerk'
import { Link, navigate } from "gatsby"
import { Modal, Loading, Grid, Input, Spacer, Button, Select } from '@geist-ui/react'
import { Search, ChevronRight } from '@geist-ui/react-icons'

const FightList = (props) => {

  const [fights, setFights] = useState([])
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState()
  const [sport, setSport] = useState()
  const [filteredfights, setFilteredFights] = useState([])
  const [ipAddress, setIPAddress] = useState()
  const [update, setUpdate] = useState()
  const [myvotes, setMyVotes] = useState([])
  const [sort, setSort] = useState("latest")
  const [upvotes, setUpVotes] = useState([])
  const [sports, setSports] = useState([])
  const [signingin, setSigningIn] = useState()

  const { isLoaded, userId, sessionId, getToken } = useAuth();


  useEffect(() => {
      if(!props.sport || props.sport === 'all') {
        fetch(`/.netlify/functions/fauna-all/`, {
            body: JSON.stringify({
              index: 'fights_all_reverse'
            }),
          method: 'POST'}).then((response) => {
            return response.json()}).then((response) => (setFights(response.data || []), setLoading()))
         .catch(error => setLoading());
      }
      else {
        fetch(`/.netlify/functions/fauna-find/`, {
            body: JSON.stringify({
              index: 'fights_by_sport_reverse',
              id: props.sport
            }),
          method: 'POST'}).then((response) => {
            return response.json()}).then((response) => (setFights(response.data || []), setLoading()))
         .catch(error => setLoading());
      }
  }, [props.sport])

  useEffect(() => {
        fetch(`/.netlify/functions/fauna-all/`, {
            body: JSON.stringify({
              index: 'sports_all'
            }),
          method: 'POST'}).then((response) => {
            return response.json()}).then((response) => (setSports(response.data || []), setLoading()))
         .catch(error => setLoading());
  }, [])

  useEffect(() => {
    if(search || sport || sort) {
      setFilteredFights(fights.filter((fight) => search ? fight[1].toLowerCase().includes(search.toLowerCase()) : true)
        .filter((fight) => props.fighter ? fight[1].includes(props.fighter) : true)
        .filter((fight) => sport ? sport === fight[2] : true)
        .sort((a, b) => sort === 'topvoted' && upvotes.filter((vote) => a[0]['@ref'].id === vote).length > upvotes.filter((vote) => b[0]['@ref'].id === vote).length ? -1 : 1)
        .sort((a, b) => sort === 'following' && myvotes.filter((vote) => a[0]['@ref'].id === vote[2]).length > myvotes.filter((vote) => b[0]['@ref'].id === vote[2]).length ? -1 : 1)
        .slice(0, props.home ? 4 : fights.length));
    }
    else {
      setFilteredFights(fights);
    }
  }, [fights, search, sport, sort, upvotes, props.fighter])


useEffect(() => {
      fetch(`/.netlify/functions/fauna-find/`, {
          body: JSON.stringify({
            index: 'votes_by_ip',
            id: userId
          }),
        method: 'POST'}).then((response) => {
          return response.json()}).then((response) => setMyVotes(response.data || []))
       .catch(error => console.log(error));
  }, [userId, update])

useEffect(() => {
    fetch(`/.netlify/functions/fauna-find/`, {
        body: JSON.stringify({
          index: 'votes_by_direction',
          id: 'up'
        }),
      method: 'POST'}).then((response) => {
        return response.json()}).then((response) => setUpVotes(response.data || []))
     .catch(error => console.log(error));
  }, [update])

useEffect(() => {
   if(props.login) {
     setSigningIn(true);
   }
 }, [props.login])



function updateFights() {
  setLoading(true);
  if(!props.sport || props.sport === 'all') {
    fetch(`/.netlify/functions/fauna-all/`, {
      body: JSON.stringify({
        index: 'fights_all_reverse'
      }),
    method: 'POST'}).then((response) => {
      return response.json()}).then((response) => (setFights(response.data || []), setLoading()))
    .catch(error => setLoading());
 }
 else {
   fetch(`/.netlify/functions/fauna-find/`, {
      body: JSON.stringify({
        index: 'fights_by_sport_reverse',
        id: props.sport
      }),
    method: 'POST'}).then((response) => {
      return response.json()}).then((response) => (setFights(response.data || []), setLoading()))
    .catch(error => setLoading());
 }
}

  return (
    <>
      <SignedOut>
        <Modal visible={signingin} className="sign-in" onClose={() => setSigningIn(false)}>
          {!props.login ? <SignUp redirectUrl={props.path}
              signInUrl={props.path + "?login=true"} /> :
              <SignIn redirectUrl={props.path} />}
         </Modal>
      </SignedOut>
      <div id="fighters" className={props.home ? "fighters-page home" : "fighters-page"}>
       <div className="sort-select">{props.sport === 'all' && <Spacer y={2} />}
       <Grid.Container gap={1}>
        {!props.short && <>
          <Grid xs={24} md={props.sport === 'all' ? 9 : 12}>
           <Input scale={1} width="100%" placeholder="Search" clearable icon={<Search />}
           onChange={(e) => setSearch(e.target.value)} />
         </Grid>
         {(!props.sport || props.sport === 'all') && <Grid xs={24} sm={12} md={9}>
          <Select scale={1} width="100%" placeholder="Choose Sport" 
            clearable onChange={(val) => setSport(val)}>
              <Select.Option value=""><span className="sports-font">%</span> Show All</Select.Option>
            {sports.map((sport) => (
              <Select.Option value={sport[0]}><span className="sports-font">{sport[1]}</span> {sport[0]}</Select.Option>
             ))}
          </Select>
         </Grid>}
         </>}
         <Grid xs={24} sm={props.short ? 24 : props.sport === 'all' ? 12 : 24} md={props.short ? 24 : props.sport === 'all' ?  6 : 12}>
          <Select scale={1} width="100%" className={props.short ? "centered" : "none"} initialValue={sort} placeholder="Sort Fights"
             onChange={(val) => setSort(val)}>
              <Select.Option value="latest">Latest</Select.Option>
              <Select.Option value="topvoted">Top Voted</Select.Option>
              <Select.Option value="following">Following</Select.Option>
          </Select>
         </Grid>
       </Grid.Container></div>
       {loading && <><Spacer y={4} /><Loading spaceRatio={2.5} /></>}
       {!loading && <>
         <Grid.Container gap={1.5} className="fighter-grid">{filteredfights.map((fight) => (
         <Grid xs={24} lg={props.short ? 24 : 12}>
          <FightCard fighterone={fight[3]} fightertwo={fight[4]} ip={userId} 
            fight={fight[0]['@ref'].id} slug={fight[1].toLowerCase().split(" ").concat(fight[2].toLowerCase().split(" ").join("-")).join("-")}
            myvote={myvotes.length ? myvotes.filter((vote) => vote[2] === fight[0]['@ref'].id) : []}
            onChange={(response) => typeof response === 'object' ? setUpdate(response) : setSigningIn(response)} 
            signingin={signingin} sport={sports.find((sport) => sport[0] === fight[2]) || []} />
         </Grid>
        ))}
       </Grid.Container>
       {filteredfights.length < 1 && <p className="centered">There are no {props.sport && props.sport !== 'all' && props.sport} fights {props.fighter ? 'for ' + props.fighter : 'matching your search'}</p>}
       </>}
        {props.home && <Button scale={.8} type="secondary" className="view-all" icon={<ChevronRight />} onClick={() => navigate('/fights/')} ghost>View All</Button>}
        <AddFight fighter={props.fighter ? props.id + "-" + props.fighter : null}  onChange={() => updateFights()} sport={props.sport} />
      </div>
    </>
  )
}

export default FightList